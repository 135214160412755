/* .recart-block1 {
  background-image: url("../img/broker.jpg");
  background-repeat: no-repeat;
  position: relative;
  height: 400px;
  border-radius: 10px;
} */
.all-article-detail .single-article {
  width: 453px;
  height: 300px;
  border-radius: 10px;
  position: relative;
}
.all-article-detail .descr-article {
  background: #3895d3 0% 0% no-repeat padding-box;
  opacity: 0.79;
  position: absolute;
  bottom: 0;
  padding: 15px;
  border-radius: 0 0 10px 10px;
  width: 453px;
  height: 130px;
}
.descr-article p {
  text-align: left;
  font: normal normal bold 20px/30px "DejaVuSAns";
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
  margin: 0;
}
.about-article {
  display: flex;
  justify-content: space-between;
}
.new-invest p {
  text-align: left;
  font: oblique normal normal 16px/30px "DejaVuSAns";
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin: 0;
}
.date-article {
  text-align: -webkit-right;
}
.date-article p {
  text-align: right;
  font: oblique normal normal 16px/30px "DejaVuSAns";
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.date-article span {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 20px #d0d4e159;
  opacity: 1;
  border-radius: 50%;
  display: block;
  width: 42px;
  height: 42px;
}
.date-article span img {
  padding: 12px;
}
@media (max-width: 468px) {
  .all-article-detail .single-article {
    width: 400px;
    height: 247px;
  }
  .all-article-detail .descr-article {
    width: 400px;
    height: 100px;
  }
}
@media (max-width: 400px) {
  .date-article p {
    font: oblique normal normal 12px/0 "DejaVuSAns";
  }
  .new-invest p {
    font: oblique normal normal 11px/0 "DejaVuSAns";
  }
  .all-article-detail .single-article {
    width: 350px;
    height: 215px;
  }
  .all-article-detail .descr-article {
    width: 350px;
    height: 90px;
  }
}
@media (max-width: 320px) {
  .all-article-detail .single-article {
    width: 330px;
    height: 200px;
  }
  .all-article-detail .descr-article {
    width: 330px;
  }
}
