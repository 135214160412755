.footer-sec {
	background: #ffffff 0% 0% no-repeat padding-box;
	opacity: 1;
	margin-top: 50px;
	padding: 20px 0;
	border-top: 1px solid #bdbdbda8;
}
.footer-col {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.links {
	display: flex;
}
.links p {
	text-align: left;
	font: normal normal 100 16px/19px "Roboto", sans-serif;
	letter-spacing: 0px;
	color: #3895d3;
	opacity: 1;
}
.thelink {
	font: normal normal 100 16px/19px "Roboto", sans-serif;
	letter-spacing: 0px;
	color: #3895d3;
	opacity: 1;
	margin: 0 7px;
}
.social-div {
	display: flex;
	margin-right: 100px;
}
.social .about-more img {
	border-radius: 20%;
}
@media (max-width: 750px) {
	.Article .links {
		display: block;
	}
	.Article .social-div {
		margin: 0;
	}
}
