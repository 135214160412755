.homepage-blog .recart-block1 {
  width: 500px;
  height: 350px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  position: relative;
}
.homepage-blog .descr-article {
  background: #3895d3 0% 0% no-repeat padding-box;
  opacity: 0.79;
  position: absolute;
  bottom: 0;
  padding: 15px;
  border-radius: 0 0 10px 10px;
  width: 100%;
  height: 170px;
}
@media (max-width: 1100px) {
  #article-row {
    display: block;
  }
  #article-row div {
    max-width: 100%;
  }
  #article-row #blocks {
    max-width: initial !important ;
  }
  .recart-block1 {
    max-width: 665px;
    margin: 20px 0;
  }
}
@media (max-width: 400px) {
  .about-article {
    padding-top: 10px;
  }
  .date-article p {
    padding-bottom: 10px;
  }
  .descr-article .descr-text {
    font: normal normal bold 17px/25px "DejaVuSAns" !important;
  }
}
