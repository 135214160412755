.page-item {
  cursor: pointer;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 550px;
  background-color: #fff;
}
