.blog-div {
  display: flex;
  padding-top: 60px;
}
.blog-title h1 {
  font: normal normal bold 70px/103px "DejaVuSAns";
  letter-spacing: 0px;
  color: #3895d3;
  text-transform: capitalize;
  opacity: 1;
}
.blog-title h2 {
  font: normal normal bold 70px/103px "DejaVuSAns";
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  width: 845px;
}
.all-article {
  margin-top: 60px;
}
.article-title h3 {
  text-align: left;
  font: normal normal 200 32px/50px "DejaVuSAns";
  letter-spacing: 0px;
  color: #3895d3;
  text-transform: capitalize;
  opacity: 1;
}
.article-title h1 {
  text-align: left;
  font: normal normal bold 42px/50px "DejaVuSAns";
  letter-spacing: 0px;
  color: #383434;
  text-transform: uppercase;
  opacity: 1;
}
.all-article-tit {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.article-btn {
  background: #f5f9fc 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: left;
  font: normal normal bold 20px/16px "DejaVuSAns";
  letter-spacing: 0px;
  color: #3895d3;
  border: none;
  width: 275px;
  height: 60px;
  position: relative;
}
.foot {
  margin-top: 60px;
}
.article-btn:hover {
  background: rgba(150, 153, 155, 0.2);
  color: #3895d3;
}
.article-btn img {
  padding-right: 15px;
}
.down-menu {
  width: 100%;
}
.dropdown-toggle::after {
  position: absolute;
  right: 30px;
}
.page-link {
  border-radius: 50% !important;
  margin: 5px;
  padding: 10px 15px !important;
}
.page-link:first-of-type {
  background: #3895d3;
  color: #f5f9fc;
  border-radius: 50% !important;
  margin: 5px;
  padding: 10px 15px !important;
}
.foot {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nextt {
  text-decoration: underline;
  padding: 10px;
}
@media (max-width: 1800px) {
  .blog-title h2 {
    font: normal normal bold 45px/70px "DejaVuSAns";
    width: 700px;
  }
  .blog-title h1 {
    font: normal normal bold 50px/70px "DejaVuSAns";
  }
}
@media (max-width: 1600px) {
  .blog-title h2 {
    width: 500px;
  }
}
@media (max-width: 1250px) {
  .blog-title h2 {
    font: normal normal bold 35px/60px "DejaVuSAns";
    width: 383px;
  }
}
@media (max-width: 1080px) {
  .blog-title h2 {
    width: 270px;
  }
}
@media (max-width: 950px) {
  .blog-div {
    display: block;
    text-align: center;
  }
  .blog-title h1 {
    font: normal normal bold 60px/90px "DejaVuSAns";
  }
  .blog-title h2 {
    font: normal normal bold 60px/90px "DejaVuSAns";
    width: auto;
  }
  #box {
    display: block;
  }
  .all-article-tit {
    display: block;
  }
  .recart-block1 {
    margin: 10px 0;
  }
}
@media (max-width: 630px) {
  .blogimg-div img {
    width: 500px;
  }
}
@media (max-width: 500px) {
  .blog-title h2 {
    font: normal normal bold 40px/70px "DejaVuSAns";
  }
  .blog-title h1 {
    font: normal normal bold 50px/70px "DejaVuSAns";
  }
  .blogimg-div img {
    width: 400px;
  }
}
@media (max-width: 380px) {
  .blogimg-div img {
    width: 300px;
  }
  .blog-title h1 {
    font: normal normal bold 40px/55px "DejaVuSAns";
  }
  .blog-title h2 {
    font: normal normal bold 26px/45px "DejaVuSAns";
  }
}
