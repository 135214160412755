@font-face {
	font-family: "DejaVuSAns";
	src: url("./Assets/fonts/ExtraLight/DejaVuSans-ExtraLight.woff");
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "DejaVuSAns";
	src: url("./Assets/fonts/DejaVuSans/DejaVuSans.woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "DejaVuSAns";
	src: url("./Assets/fonts/Bold/DejaVuSans-Bold.woff");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "DejaVuSAns";
	src: url("./Assets/fonts/Oblique/DejaVuSansCondensed-Oblique.woff");
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
