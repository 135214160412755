.latestprop {
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 60px 0;
  position: relative;
}
.show-all {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 40px;
}
.latest-title h5 {
  text-align: left;
  font: normal normal 200 32px/50px "DejaVuSAns";
  letter-spacing: 0px;
  color: #3895d3;
  text-transform: capitalize;
  opacity: 1;
}
.latest-title h2 {
  text-align: left;
  font: normal normal bold 42px/50px "DejaVuSAns";
  letter-spacing: 0px;
  color: #383434;
  opacity: 1;
}
.showbtn {
  background: #3895d3 0% 0% no-repeat padding-box !important;
  border-radius: 40px !important;
  opacity: 1 !important;
  border: none !important;
  font: normal normal bold 18px/22px "Roboto", sans-serif !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  text-transform: uppercase !important;
  padding: 20px 30px !important;
  width: 220px;
  height: 60px;
}
.showbtn img {
  margin-left: 25px;
}
#rotateImg {
  transform: rotate(-90deg);
}
.main-img img {
  width: 100%;
  position: relative;
}
.main-img span {
  position: absolute;
  top: 10px;
  left: auto;
  right: 30px;
  background: #f9bb11 0% 0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
  text-align: left;
  font: normal normal normal 14px/17px "Roboto", sans-serif;
  letter-spacing: 0px;
  padding: 2px 10px;
  color: #ffffff;
  z-index: 2;
}
.block {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 20px #d0d4e159;
  border-radius: 0px 0px 7px 7px;
  opacity: 1;
}
.block-content {
  padding: 15px;
}
.blockp {
  text-align: left;
  font: normal normal normal 14px/20px "Roboto", sans-serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.block-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.block-title h5 {
  text-align: left;
  font: normal normal bold 22px/25px DejaVu Sans;
  letter-spacing: 0px;
  color: #383b41;
  opacity: 1;
}
.block-title p {
  text-align: left;
  font: normal normal bold 14px/17px "Roboto", sans-serif;
  letter-spacing: 0px;
  color: #383b41;
  opacity: 1;
}
.descr {
  display: flex;
  align-items: center;
}
.descr span {
  text-align: left;
  font: normal normal bold 20px/24px DejaVu Sans;
  letter-spacing: 0px;
  color: #3895d3;
  opacity: 1;
  padding: 0 15px 0 15px;
}
.price-more {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.price h5 {
  text-align: left;
  font: normal normal bold 20px/24px DejaVu Sans;
  letter-spacing: 0px;
  color: #3895d3;
  opacity: 1;
}
.about-more img {
  padding: 10px;
  margin: 4px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 20px #d0d4e159;
  opacity: 1;
  border-radius: 45%;
  width: 42px;
  height: 42px;
}
#block2 {
  margin-top: 20px !important;
}
.sold-span {
  background: #f91111 0% 0% no-repeat padding-box !important;
  padding: 2px 22px !important;
}
.modal-dialog {
  top: 30px;
}
.mod-head {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.modal-header .close {
  margin: 0 !important;
  padding: 1rem 0 1rem 1rem !important;
}
.modal-content {
  width: 90% !important;
  margin: 0 auto;
}
.modal-header {
  border-bottom: none !important;
}
.modal-dialog {
  max-width: none !important;
  margin: 0 !important;
}
.modal-header {
  padding: 0 20px !important;
}
.mod-logo img {
  height: 74px;
  width: 96px;
}
.mod-body {
  background: #f4f6f8 0% 0% no-repeat padding-box;
}

.body-content {
  padding: 40px;
}
.body-left {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 30px;
}
.body-left h3 {
  text-align: left;
  font: normal normal bold 22px/35px DejaVu Sans;
  letter-spacing: 0px;
  color: #3895d3;
  opacity: 1;
}
.body-left h6 {
  text-align: left;
  font: normal normal bold 12px/25px DejaVu Sans;
  letter-spacing: 0px;
  color: #a7a7a7;
  opacity: 1;
  margin: 0;
}
.body-left p {
  text-align: left;
  font: normal normal normal 14px/26px "Roboto", sans-serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin: 0;
}
.mod-description {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mod-descr div {
  display: flex;
  align-items: center;
  margin: 15px 0;
}
.mod-descr p {
  text-align: left;
  font: normal normal normal 18px/21px DejaVu Sans;
  letter-spacing: 0px;
  color: #9e9e9e;
  text-transform: lowercase;
  opacity: 1;
  padding-left: 10px;
}
.mod-descr2 h6 {
  text-align: right;
  font: normal normal bold 20px/24px DejaVu Sans;
  letter-spacing: 0px;
  color: #3895d3;
  opacity: 1;
  margin: 15px 0;
}
.body-left h2 {
  text-align: right;
  font: normal normal bold 26px/42px DejaVu Sans;
  letter-spacing: 0px;
  color: #3895d3;
  opacity: 1;
  border-bottom: 1px solid #3895d3;
}
.agent-name {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.name-img img {
  width: 74px;
  height: 74px;
  border-radius: 50%;
}
.info-name {
  margin-left: 15px;
}
.info-name h5 {
  text-align: left;
  font: normal normal 200 20px/25px DejaVu Sans;
  letter-spacing: 0px;
  color: #3895d3;
  text-transform: capitalize;
  opacity: 1;
}
.info-name h4 {
  text-align: left;
  font: normal normal bold 20px/24px DejaVu Sans;
  letter-spacing: 0px;
  color: #383434;
  opacity: 1;
}
.agentContact {
  position: relative;
}

.agentContact h5 {
  text-align: left;
  font: normal normal 200 20px/25px DejaVu Sans;
  letter-spacing: 0px;
  color: #3895d3;
  text-transform: capitalize;
  margin: 20px 0px;
}
.agentContact form {
  display: flex;
  flex-direction: column;
}
.agentContact form input,
.agentContact form textarea {
  border: none;
  margin-bottom: 5px;
  border-bottom: 2px solid #3895d3;
  border-radius: 0px;
  resize: none;
}
.agentContact form input::placeholder,
.agentContact form textarea::placeholder {
  color: #3895d3;
}
.agentContact form input:focus,
.agentContact form textarea:focus {
  outline: none !important;
  box-shadow: none !important;
}
.agentContact form button {
  text-transform: uppercase;
  margin-top: 35px;
  border-radius: 30px;
  padding: 10px 25px;
  font-size: 16px;
  font-weight: bold;
}
.agentContact .form-control {
  padding-left: 0px;
}
/* //////////slider */

.carousel {
  border-radius: 15px;
  display: flex !important;
  justify-content: center !important;
}
.thumbs-wrapper {
  position: absolute !important;
  bottom: 0 !important;
}
.thumbs-wrapper ul.thumbs {
  padding-inline-start: 0px !important;
}
.carousel-status {
  display: none;
}
.thumbs-wrapper .thumb {
  border-radius: 15px;
  padding: 0px;
  border: none !important;
  transition: none !important;
  width: 120px !important;
}
.thumbs-wrapper .thumb.selected,
.thumbs-wrapper .thumb:hover {
  border: 3px solid #3895d3 !important;
}
.control-dots {
  display: none;
}
.thumbs li img {
  border-radius: 15px;
}

.arrowBtn {
  height: 17px;
  z-index: 1000;
}
.nosuch {
  font: bold 25px DejaVu Sans;
  letter-spacing: 0px;
  color: brown;
  opacity: 1;
  text-transform: uppercase;
}
@media (max-width: 1060px) {
  #block2 {
    display: block;
  }
  #allcols {
    margin: 20px 0;
  }
  .main-img span {
    left: auto;
    right: 60px;
    top: 25px;
    font: normal normal normal 20px/25px "Roboto", sans-serif;
    padding: 2px 15px;
  }
  .sold-span {
    padding: 3px 28px !important;
  }
}
@media (max-width: 686px) {
  .latest-title h2 {
    font: normal normal bold 30px/35px DejaVu Sans;
  }
  .latest-title h5 {
    font: normal normal 200 25px/35px DejaVu Sans;
  }
  .showbtn {
    font: normal normal bold 15px/18px "Roboto", sans-serif !important;
    padding: 13px 11px !important;
  }
}
@media (max-width: 480px) {
  .latest-title h2 {
    font: normal normal bold 18px/20px DejaVu Sans;
  }
  .latest-title h5 {
    font: normal normal 200 15px/20px DejaVu Sans;
  }
  .showbtn {
    font: normal normal bold 12px/8px "Roboto", sans-serif !important;
    padding: 10px 4px !important;
  }
  .main-img span {
    right: 40px;
    top: 10px;
    font: normal normal normal 12px/20px "Roboto", sans-serif;
    padding: 2px 10px;
  }
  .sold-span {
    padding: 2px 17px !important;
  }
  .showbtn img {
    margin-left: 12px;
  }
  .showbtn {
    margin: 0;
    width: 135px;
    height: 40px;
  }
}

@media (max-width: 400px) {
  .block-title h5 {
    font: normal normal bold 13px/25px DejaVu Sans;
  }
  .descr span {
    font: normal normal bold 13px/24px DejaVu Sans;
  }
  .price h5 {
    font: normal normal bold 16px/24px DejaVu Sans;
  }
  .showbtn {
    font: normal normal bold 15px/18px "Roboto", sans-serif !important;
    padding: 13px 11px !important;
  }
}
@media (max-width: 480px) {
  .latest-title h2 {
    font: normal normal bold 18px/20px "DejaVuSAns";
  }
  .latest-title h5 {
    font: normal normal 200 15px/20px "DejaVuSAns";
  }
  .showbtn {
    font: normal normal bold 12px/8px "Roboto", sans-serif !important;
    padding: 10px 4px !important;
  }
  .main-img span {
    right: 40px;
    top: 10px;
    font: normal normal normal 12px/20px "Roboto", sans-serif;
    padding: 2px 10px;
  }
  .sold-span {
    padding: 2px 17px !important;
  }
  .showbtn img {
    margin-left: 12px;
  }
  .showbtn {
    margin: 0;
    width: 135px;
    height: 40px;
  }
}
