.main-descr h6 {
  text-align: left;
  font: oblique normal bold 20px/100px "DejaVuSAns";
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.main-descr p:first-of-type {
  text-align: left;
  font: normal normal bold 22px/35px "Roboto", sans-serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.main-descr p:nth-of-type(2) {
  text-align: left;
  font: normal normal normal 18px/35px "Roboto", sans-serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin: 0;
}
.main-descr p:last-of-type {
  text-align: left;
  font: normal normal normal 18px/35px "Roboto", sans-serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.flexdivs {
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;
}
.main-descr {
  width: 70%;
}
.popular {
  width: 32%;
  border-left: 2px solid #3895d3;
  padding-left: 20px;
}
.popular h3 {
  text-align: left;
  font: normal normal 200 32px/50px "DejaVuSAns";
  letter-spacing: 0px;
  color: #3895d3;
  text-transform: capitalize;
  opacity: 1;
}
.item-name {
  margin-left: 10px;
}
.item-name h6 {
  text-align: left;
  font: oblique normal bold 14px/30px "DejaVuSAns";
  letter-spacing: 0px;
  color: #3895d3;
  opacity: 1;
}
.item-name h5 {
  text-align: left;
  font: normal normal bold 16px/25px "DejaVuSAns";
  letter-spacing: 0px;
  color: #383434;
  text-transform: uppercase;
  opacity: 1;
  margin: 0;
}
.item-name p {
  text-align: left;
  font: oblique normal normal 16px/30px "DejaVuSAns";
  letter-spacing: 0px;
  color: #383434;
  opacity: 1;
}
.popular-item {
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.popular-img {
  border-radius: 5px;
}
.popular-img img {
  width: 171px;
  height: 129px;
  border-radius: 5px;
}
.sep-item {
  text-align: center;
  margin-top: 40px;
  padding: 0 70px;
}
.sep-item h6 {
  text-align: left;
  font: normal normal bold 22px/35px "Roboto", sans-serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding-top: 40px;
}
.sep-item p {
  text-align: left;
  font: normal normal normal 18px/35px "Roboto", sans-serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

@media (max-width: 1700px) {
  .recart-block1 {
    width: auto !important;
  }
}
@media (max-width: 1300px) {
  .item-name h6 {
    font: oblique normal bold 10px/20px "DejaVuSAns";
  }
  .item-name h5 {
    font: normal normal bold 12px/20px "DejaVuSAns";
  }
  .item-name p {
    font: oblique normal normal 12px/22px "DejaVuSAns";
  }
  .sep-item img {
    width: 800px;
  }
}
@media (max-width: 1100px) {
  .flexdivs {
    display: block;
  }
  .main-descr {
    width: auto;
  }
  .popular {
    width: auto;
  }
  .rel-article #article-row {
    display: block;
  }
  .rel-article #blocks {
    max-width: initial;
    margin: 20px 0;
  }
  .rel-article .descr-article {
    width: 454px;
  }
}
@media (max-width: 990px) {
  .sep-item img {
    width: 700px;
  }
}
@media (max-width: 790px) {
  .sep-item img {
    width: 588px;
  }
}
@media (max-width: 600px) {
  .sep-item {
    padding: 0;
  }
  .sep-item img {
    width: 450px;
  }
}
@media (max-width: 500px) {
  .sep-item img {
    width: 350px;
  }
}
@media (max-width: 400px) {
  .sep-item img {
    width: 250px;
  }
}
@media (max-width: 360px) {
  .item-name h5 {
    font: normal normal bold 10px/16px "DejaVuSAns";
  }
}
@media (max-width: 320px) {
  .item-name h6 {
    font: oblique normal bold 8px/16px "DejaVuSAns";
  }
  .item-name h5 {
    font: normal normal bold 8px/14px "DejaVuSAns";
  }
  .item-name p {
    font: oblique normal normal 8px/16px "DejaVuSAns";
  }
}
