/* .article-slide {
  height: 880px;
  width: 1905px;
} */
.article-slide .image {
  height: 880px;
}
.slide-title p {
  text-align: left;
  font: normal normal bold 20px/30px "DejaVuSAns";
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
  width: 668px;
}
.slide-title h6 {
  text-align: left;
  font: oblique normal normal 16px/30px "DejaVuSAns";
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.slider-div {
  background: #3895d3 0% 0% no-repeat padding-box;
  border-radius: 10px 10px 0px 0px;
  opacity: 0.65;
  padding: 30px 0;
  position: absolute;
  bottom: -146px;
  width: 100%;
}
.slick-dots {
  bottom: 200px !important;
}
.slick-prev:before {
  color: #3895d3 !important;
  position: absolute !important;
  left: 200px !important;
  font-size: 40px !important;
}
.slick-next:before {
  color: #3895d3 !important;
  font-size: 40px !important;
  position: absolute !important;
}

.inform {
  display: flex;
}
.inform span {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 20px #d0d4e159;
  opacity: 1;
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 5px;
}
.inform span img {
  padding: 15px;
}
.moreabout-slide {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
