.bannerjpg {
  position: absolute;
  top: 0;
  width: 100%;
  height: 980px;
}
.main-titles h1:first-of-type {
  text-align: left;
  font: normal normal bold 70px/103px "DejaVuSAns";
  letter-spacing: 0px;
  color: #3895d3;
}
.main-titles h1:nth-of-type(2) {
  text-align: left;
  font: normal normal bold 70px/103px "DejaVuSAns";
  letter-spacing: 0px;
  color: #383434;
}
.main-titles h1:last-of-type {
  text-align: left;
  font: normal normal bold 70px/103px "DejaVuSAns";
  letter-spacing: 0px;
  color: #383434;
}
.main-titles p {
  text-align: left;
  font: normal normal normal 20px/36px "DejaVuSAns";
  letter-spacing: 0px;
  color: #000000;
  opacity: 0;
  width: 613px;
  height: 110px;
}
.title {
  padding: 60px 25px;
}
.how-works {
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-top: 60px;
}
.works-title {
  text-align: center;
}
.works-title h6 {
  font: normal normal 200 32px/50px "DejaVuSAns";
  letter-spacing: 0px;
  color: #3895d3;
  text-transform: capitalize;
  opacity: 1;
}
.works-title h2 {
  font: normal normal bold 42px/50px "DejaVuSAns";
  letter-spacing: 0px;
  color: #383434;
  text-transform: uppercase;
  opacity: 1;
}
.imgdivs {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 40px;
}
.imgdivs div {
  text-align: center;
}
.imgdivs h6 {
  font: normal normal bold 20px/16px "DejaVuSAns";
  letter-spacing: 0px;
  color: #a9adb5;
  opacity: 1;
  margin: 20px 0;
}
.imgdivs h2 {
  font: normal normal bold 20px/16px "DejaVuSAns";
  letter-spacing: 0px;
  color: #383434;
  text-transform: uppercase;
  opacity: 1;
}
#imagefst {
  width: 322px;
  height: 208px;
}
#imagesec {
  width: 320px;
  height: 228px;
}
#imagethrd {
  width: 294px;
  height: 208px;
}
#comp {
  width: "140px";
  height: "20px";
}

.about-content {
  display: flex;
}
.about-info h3 {
  text-align: left;
  font: normal normal 200 32px/100px "DejaVuSAns";
  letter-spacing: 0px;
  color: #3895d3;
  text-transform: capitalize;
  opacity: 1;
}
.about-info p {
  text-align: left;
  font: normal normal normal 20px/36px "DejaVuSAns";
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  width: 605px;
  margin: 0;
}
.text {
  text-align: left;
  font: normal normal normal 20px/36px "DejaVuSAns";
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  width: 605px;
}
.about-txt .card {
  border: none;
}
.about-txt .card-header {
  border: none;
  background: none;
  padding: 30px 0;
}
.about-txt .card-body {
  flex: none;
  min-height: auto;
  padding: 0;
}
.about-txt .showbtn {
  text-decoration: none;
}
.about-txt .showbtn:hover {
  text-decoration: none;
}

.map-sec {
  margin-top: 80px;
  background-image: url("../img/map.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 680px;
  position: relative;
}

.map-location img {
  top: 240px;
  left: 670px;
  position: absolute;
  right: auto;
}
.main-contacts {
  background: rgba(56, 149, 211, 0.5);
  border-radius: 10px 10px 0px 0px;
  width: 560px;
  padding: 0 0 30px 60px;
  position: absolute;
  left: auto;
  right: 300px;
  top: 200px;
}
.main-contacts h2 {
  text-align: left;
  font: normal normal 200 43px/134px "DejaVuSAns";
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
}
.main-contacts h5 {
  text-align: left;
  font: normal normal bold 21px/60px "DejaVuSAns";
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.main-contacts img {
  margin-right: 30px;
}
.footer-sec {
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  margin: 20px 0;
}
.footer-col {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.links {
  display: flex;
}
.links p {
  text-align: left;
  font: normal normal 100 16px/19px "Roboto", sans-serif;
  letter-spacing: 0px;
  color: #3895d3;
  opacity: 1;
}
.thelink {
  font: normal normal 100 16px/19px "Roboto", sans-serif;
  letter-spacing: 0px;
  color: #3895d3;
  opacity: 1;
  margin: 0 7px;
}
.social-div {
  display: flex;
  margin-right: 100px;
}
.social .about-more img {
  border-radius: 20%;
}

@media (max-width: 1900px) {
  .map-location img {
    display: none;
  }
}
@media (max-width: 1370px) {
  .about-content {
    display: block !important;
  }
  .aboutimg-div {
    text-align: center;
  }
  .aboutimg-div img {
    width: 650px;
    height: auto;
  }
}
@media (max-width: 1200px) {
  #imagefst {
    width: 200px;
    height: auto;
  }
  #imagesec {
    width: 198px;
    height: auto;
  }
  #imagethrd {
    width: 174px;
    height: auto;
  }
  #comp {
    width: 100px;
    height: auto;
  }
  .imgdivs h6 {
    font: normal normal bold 15px/11px "DejaVuSAns";
  }
  .imgdivs h2 {
    font: normal normal bold 15px/10px "DejaVuSAns";
  }
}
@media (max-width: 1020px) {
  .main-contacts {
    right: 180px;
  }
}
@media (max-width: 840px) {
  .imgdivs {
    display: block;
  }
  #imagefst {
    width: auto;
  }
  #imagesec {
    width: auto;
  }
  #imagethrd {
    width: auto;
  }
  #comp {
    width: auto;
  }
  .main-contacts {
    right: 120px;
  }
  .footer-col {
    display: block;
  }
  .social-div {
    margin: 0;
  }
}
@media (max-width: 660px) {
  .aboutimg-div img {
    width: 500px;
    height: auto;
  }
  .map-sec {
    background: #ffffff;
  }
  .main-contacts {
    width: auto;
    position: none;
    left: 0;
    right: 0;
    top: 0;
  }
  .map-sec {
    height: 360px;
  }
  .about-info p {
    width: 400px;
  }
}
@media (max-width: 500px) {
  .aboutimg-div img {
    width: 380px;
    height: auto;
  }
  .about-info p {
    font: normal normal normal 12px/25px "DejaVuSAns";
    font: normal normal normal 12px/25px DejaVu Sans;
    width: 350px;
  }
  .about-info h3 {
    font: normal normal 200 22px/50px "DejaVuSAns";
  }
  .main-contacts h2 {
    font: normal normal 200 30px/95px "DejaVuSAns";
  }
  .main-contacts h5 {
    font: normal normal bold 15px/40px "DejaVuSAns";
  }
  .map-sec {
    height: 260px;
  }
  .links {
    display: block;
  }
  .footer-col {
    display: block !important;
  }
}
@media (max-width: 430px) {
  .works-title h6 {
    font: normal normal 200 20px/26px "DejaVuSAns";
  }
  .works-title h2 {
    font: normal normal bold 26px/30px "DejaVuSAns";
  }
  .how-works {
    margin: 0;
  }
  .about-info p {
    width: 250px;
  }
}
@media (max-width: 380px) {
  .aboutimg-div img {
    width: 280px;
    height: auto;
  }
  #imagefst {
    width: 280px;
  }
  #imagesec {
    width: 280px;
  }
  #imagethrd {
    width: 280px;
  }
  #comp {
    width: 200px;
  }
  .main-contacts {
    padding: 0 0 30px 40px;
  }
  .main-contacts h5 {
    font: normal normal bold 12px/30px "DejaVuSAns";
  }
  .map-sec {
    height: 220px;
  }
  #footer-divs {
    display: block;
  }
}
