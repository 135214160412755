.main-menu {
  background: transparent;
  justify-content: space-evenly;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 15px 0;
  position: relative;
  z-index: 1;
}
#menuu .menulinks {
  text-align: left;
  font: normal normal normal 24px/28px "DejaVuSAns";
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  transition: 0.3s;
  margin: 0 15px;
  text-decoration: none;
  cursor: pointer;
}
#menuu .menulinks:hover {
  color: #3895d3;
}

.main-nav-active {
  text-align: left !important;
  font: normal normal bold 24px/28px "DejaVuSAns" !important;
  letter-spacing: 0px !important;
  color: #3895d3 !important;
}
.thisbtn {
  background: rgba(56, 149, 211, 0.6) 0% 0% no-repeat padding-box !important;
  border: none !important;
  border-radius: 33px !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  font: normal normal bold 20px/40px "DejaVuSAns" !important;
  padding: 7px 20px !important;
  width: 273px;
  height: 60px;
}
.thisbtn img {
  padding-right: 10px;
}

@media (max-width: 1200px) {
  .menu .navbar-light .navbar-nav .nav-link {
    margin: 2px !important;
  }
}
@media (max-width: 1060px) {
  .Info {
    margin-top: 70px !important;
  }
}
@media (max-width: 1020px) {
  .main-titles h1:first-of-type {
    font: normal normal bold 55px/70px "DejaVuSAns" !important;
  }
  .main-titles h1:nth-of-type(2) {
    font: normal normal bold 55px/70px "DejaVuSAns" !important;
  }
  .main-titles h1:last-of-type {
    font: normal normal bold 55px/70px "DejaVuSAns" !important;
  }
  .main-titles p {
    font: normal normal normal 17px/30px "DejaVuSAns" !important;
  }
}
@media (max-width: 990px) {
  .menu .navbar-light .navbar-toggler {
    margin: 10px auto !important;
  }
  .main-menu {
    background: #ffffff;
  }
}
@media (max-width: 686px) {
  .main-titles h1:first-of-type {
    font: normal normal bold 35px/50px "DejaVuSAns" !important;
  }
  .main-titles h1:nth-of-type(2) {
    font: normal normal bold 35px/50px "DejaVuSAns" !important;
  }
  .main-titles h1:last-of-type {
    font: normal normal bold 35px/50px "DejaVuSAns" !important;
  }
  .main-titles p {
    font: normal normal normal 15px/26px "DejaVuSAns" !important;
    width: 500px !important;
  }
}
@media (max-width: 530px) {
  .main-menu {
    display: block;
    text-align: center;
  }
  .main-titles h1:first-of-type {
    font: normal normal bold 25px/40px "DejaVuSAns" !important;
  }
  .main-titles h1:nth-of-type(2) {
    font: normal normal bold 25px/40px "DejaVuSAns" !important;
  }
  .main-titles h1:last-of-type {
    font: normal normal bold 25px/40px "DejaVuSAns" !important;
  }
  .main-titles p {
    font: normal normal normal 15px/26px "DejaVuSAns" !important;
    width: 370px !important;
  }
}
@media (max-width: 400px) {
  .thisbtn {
    padding: 0;
    font: normal normal bold 15px/30px "DejaVuSAns" !important;
  }
  .main-titles h1:first-of-type {
    font: normal normal bold 20px/30px "DejaVuSAns" !important;
  }
  .main-titles h1:nth-of-type(2) {
    font: normal normal bold 20px/30px "DejaVuSAns" !important;
  }
  .main-titles h1:last-of-type {
    font: normal normal bold 20px/30px "DejaVuSAns" !important;
  }
  .main-titles p {
    font: normal normal normal 15px/26px "DejaVuSAns" !important;
    width: 280px !important;
  }
}
@media (max-width: 320px) {
  .dropdown-toggle::after {
    right: 10px !important;
  }
}
