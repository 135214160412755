.searching {
  border-radius: 10px 10px 0px 0px;
  background: rgba(56, 149, 211, 0.6) 0% 0% no-repeat padding-box;
  position: relative;
  width: 100%;
}
.thisrow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 30px 0;
  margin: 0px !important;
}
.thisrow h3 {
  text-align: left;
  font: normal normal bold 22px/36px DejaVu Sans;
  letter-spacing: 0px;
  color: #ffffff;
}
.thisrow input {
  border-radius: 10px;
  opacity: 1;
  padding: 16px 20px;
  border: none;
  color: #3895d3;
  font: normal normal bold 20px/16px "DejaVuSAns";
  outline: none;
  margin-right: 20px;
}

.search-btn {
  background-color: #3895d3 !important;
  font: normal normal bold 18px/23px "DejavuSAns" !important;
  color: #ffffff !important;
  text-transform: uppercase;
  padding: 16px 20px;
  border: none;
  border-radius: 10px;
  transition: 0.8s ease;
}
button:disabled,
button[disabled] {
  background-color: #cccccc !important;
  color: #666666 !important;
  transition: 0.8s ease;
}

.error {
  color: red;
  font-weight: bold;
  font-size: 20px;
  position: absolute;
}
@media (max-width: 480px) {
  .searchDiv {
    text-align: center;
  }
  .thisrow input {
    margin: 20px;
    width: 270px;
  }
}
